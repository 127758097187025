import React, { FunctionComponent, PropsWithChildren } from 'react';
import { ImageFieldsFragment, VideoFieldsFragment } from '../../../__generated__/graphql-client-types';
import { generateCloudinaryImageProps } from '../../../helpers/cloudinary-helper/cloudinary-helper';
import { Link, MaybeLink } from '../../common-components/link/link.component';
import { VideoCard } from '../../common-components/video-card/video-card.component';
import { CloudinaryImage } from '../../images/cloudinary-image/cloudinary-image.component';
import { CaptionOverlay } from '../../common-components/caption-overlay/caption-overlay.component';

export type RelatedArticleProps = {
	url: string | null;
	title: string | null;
	image: ImageFieldsFragment | null;
	video: VideoFieldsFragment | null;
	largeWidth?: 'half' | 'quarter'; // on desktop, do we render 4 across or 2
};

export const RelatedArticle: FunctionComponent<RelatedArticleProps> = ({ url, title, image, video, largeWidth = 'half' }) => {
	const captionText = title || '';
	return (
		<div className={`w-100 w-50-ns pa2${largeWidth === 'quarter' ? ' w-25-l' : ''}`}>
			{image && (
				<MaybeLink url={url}>
					<ArticleCaptionOverlay captionText={captionText}>
						<CloudinaryImage
							{...generateCloudinaryImageProps(
								image,
								{ crop: 'lfill', gravity: 'custom', width: 442, height: 274 },
								{ useDimensions: false }
							)}
							className="w-100 border-box"
						/>
					</ArticleCaptionOverlay>
				</MaybeLink>
			)}
			{/* Video has no enclosing Link so it can be previewed in a modal (default video behavior) */}
			{video && (
				<ArticleCaptionOverlay captionText={captionText} linkUrl={url}>
					<VideoCard video={video} previewWidthPx={442} previewHeightPx={274} />
				</ArticleCaptionOverlay>
			)}
		</div>
	);
};

const ArticleCaptionOverlay: FunctionComponent<PropsWithChildren<{ captionText: string; linkUrl?: string | null }>> = ({
	captionText,
	linkUrl,
	children
}) => {
	return (
		<CaptionOverlay
			caption={
				<div className={'f6'}>
					<div className={'b theme-grey-darker'}>{captionText}</div>
					{/* Video captions need explicit links, while image captions are just spans because the whole image is the link */}
					{linkUrl ? (
						<Link url={linkUrl} underlineHover={true}>
							Read Article
						</Link>
					) : (
						<span className={'underline-hover'}>Read Article</span>
					)}
				</div>
			}>
			{children}
		</CaptionOverlay>
	);
};

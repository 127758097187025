import { ReactElement } from 'react';

type TabInfoParams = {
	tab: ReactElement;
	activeTab: string;
	isNotSmall: boolean;
	handleTabClicked: Function;
	setActiveTab: Function;
};

export type ResponsiveTabOptions = {
	name: string;
	onClick?: Function;
	isActive?: boolean;
	startOpen?: boolean;
	tabClasses?: string;
	activeTabClasses?: string;
	inactiveTabClasses?: string;
	analyticsHook?: string;
};

export const getTabProps = ({ tab, activeTab, isNotSmall, handleTabClicked, setActiveTab }: TabInfoParams): ResponsiveTabOptions => {
	const { name, startOpen, tabClasses, activeTabClasses, inactiveTabClasses, analyticsHook } = tab.props;

	let isActive = tab.props.name === activeTab;

	if (!isNotSmall) {
		// Any tabs set to start open should be open on mobile
		isActive = startOpen;
	}

	if (isNotSmall && startOpen && !activeTab) {
		setActiveTab(name);
		isActive = startOpen;
	}

	return {
		isActive,
		name,
		onClick: handleTabClicked,
		startOpen,
		tabClasses,
		activeTabClasses,
		inactiveTabClasses,
		analyticsHook
	};
};

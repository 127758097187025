import React, { FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { generateDataSelector } from '../../../helpers/general-helper/general-helper';
import { handleKeys } from '../../../helpers/keyboard/keyboard.helper';
import { useBreakpoints } from '../../../hooks/breakpoints/breakpoints.hook';
import { useExpandableBehavior } from '../../../hooks/expandable/expandable.hooks';
import { ChevronDownIcon, ChevronUpIcon } from '../../svg/icons.component';
import { getTabProps, ResponsiveTabOptions } from './responsive-tabs.helpers';
import { activeTabOffset } from './responsive-tabs.css';

const Indicator: FunctionComponent<{ isExpanded: boolean }> = ({ isExpanded }) => {
	return (
		<div data-testid="indicator" className="dn-ns f-inherit lh-solid ml3 flex items-center" data-automation="expand">
			{isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
		</div>
	);
};

export const ResponsiveTab: FunctionComponent<PropsWithChildren<ResponsiveTabOptions>> = ({
	name,
	children,
	onClick,
	isActive = false,
	tabClasses = '',
	activeTabClasses = '',
	inactiveTabClasses = '',
	analyticsHook
}) => {
	const { isNotSmall } = useBreakpoints();

	const { isExpanded, handleClick, toggle } = useExpandableBehavior({
		startExpanded: isActive
	});

	const handleUserClick = () => {
		if (onClick) {
			onClick(name, isExpanded); // => Set active tab name in parent
		}
		handleClick();
	};

	useEffect(() => {
		if (isNotSmall) {
			if ((!isExpanded && isActive) || (isExpanded && !isActive)) {
				toggle();
			}
		}
	}, [isExpanded, isActive, toggle, isNotSmall]);

	const activeTabClassesWithOffset = `${activeTabClasses} ${activeTabOffset}`.trim();

	const className = `pointer ${tabClasses} ${isActive ? activeTabClassesWithOffset : inactiveTabClasses}`.trim();

	return (
		<>
			<div
				role="tab"
				className={className}
				aria-label={`${name} tab`}
				onClick={handleUserClick}
				onKeyDown={handleKeys(['Enter', ' '], handleUserClick)}
				data-analytics={generateDataSelector('tab', analyticsHook)}
				tabIndex={0}>
				<div className="w-100 w-auto-ns flex title justify-between">
					<span>{name}</span>
					<Indicator isExpanded={isExpanded} />
				</div>
			</div>
			<div role="tabpanel" className={`w-100 order-last-ns overflow-y-auto-ns`}>
				{isExpanded && children}
			</div>
		</>
	);
};

export type ResponsiveTabGroupProps = {
	tabGroupClasses?: string;
	tabListClasses?: string;
	startingTabName: string;
	testId?: string;
};

export const ResponsiveTabGroup: FunctionComponent<PropsWithChildren<ResponsiveTabGroupProps>> = ({
	tabGroupClasses,
	children,
	startingTabName,
	tabListClasses,
	testId
}) => {
	const { isNotSmall } = useBreakpoints();
	const [activeTab, setActiveTab] = useState(startingTabName || '');

	const handleTabClicked = (tabName, currentState) => {
		if (isNotSmall) {
			setActiveTab(tabName);
		} else {
			// Set active tab if accordion is opening
			if (!currentState) {
				setActiveTab(tabName);
			}
		}
	};

	const Tabs = React.Children.map(children, (child: ReactElement, tabIndex) => {
		// Child can be falsy when tab conditionally rendered
		if (!child) {
			return null;
		}

		const tabProps = getTabProps({ tab: child, activeTab, isNotSmall, handleTabClicked, setActiveTab });

		return (
			<ResponsiveTab {...tabProps} key={`${tabProps.name}-${tabIndex}`}>
				{child.props.children}
			</ResponsiveTab>
		);
	});

	return (
		<section className={tabGroupClasses} data-testid={testId}>
			<div className={`flex flex-wrap flex-column flex-row-ns ${tabListClasses || 'bb-ns b--theme-tertiary'}`}>{Tabs}</div>
		</section>
	);
};
